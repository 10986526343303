import React from 'react'
import './contformstyle.css'
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";
import { useState, useEffect } from 'react';

function ContForm() {

    // Initialize useForm for validation and submission handling
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    // States for success and result messages
    const [isSuccess, setIsSuccess] = useState(false);
    const [result, setResult] = useState(null);
  
    // Your Web3Forms access key
    const accessKey = "53eb20ff-8fd2-4306-91f1-359ce7749549";
  
    // Web3Forms submission logic with success and error handling
    const { submit: onSubmit } = useWeb3Forms({
      access_key: accessKey,
      settings: {
        from_name: "Avanza user",
        subject: "New User Message from your Website",
      },
      onSuccess: (msg, data) => {
        setIsSuccess(true);
        setResult(msg);
        reset(); // Reset form on success
  
        // Show success message using SweetAlert
        Swal.fire({
          title: "Thank You!",
          text: "Your message has been successfully received!",
          icon: "success"
        });
      },
      onError: (msg, data) => {
        setIsSuccess(false);
        setResult(msg); // Set error message on failure
      },
    });
  
    // Handle form submission with validation
    const handleFormSubmit = (data) => {
      onSubmit(data); // Call the Web3Forms submission function
    };

  return (
    <div >
        <div className='portion1-2'>
          <h3 style={{fontSize:'30px', color:'#243043',textAlign:'center'}}>Shedule a call with us</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="name">Name*</label> <br />
      <input
        type="text"
        name="name"
        {...register('name', { required: 'Name is required', minLength: { value: 2, message: 'Name should have at least 2 characters' } })}
      />
      {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>} <br />

      <label htmlFor="email">Email*</label> <br />
      <input
        type="email"
        name="email"
        {...register('email', {
          required: 'Email is required',
          pattern: { value: /^\S+@\S+$/i, message: 'Enter a valid email' }
        })}
      />
      {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>} <br />

      <label htmlFor="number">Phone Number*</label> <br />
      <input
        type="text"
        name="number"
        {...register('number', {
          required: 'Phone number is required',
          pattern: { value: /^[0-9]+$/, message: 'Enter a valid phone number' }
        })}
      />
      {errors.number && <p style={{ color: 'red' }}>{errors.number.message}</p>} <br />

      <label htmlFor="company_name">Company*</label> <br />
      <input
        type="text"
        name="company_name"
        {...register('company_name', { required: 'Company name is required' })}
      />
      {errors.company_name && <p style={{ color: 'red' }}>{errors.company_name.message}</p>} <br />

      <label htmlFor="message">Your Message</label> <br />
      <input
        style={{ height: '150px' }}
        name="message"
        className="msg"
        type="text"
        {...register('message', { required: 'Message is required' })}
      />
      {errors.message && <p style={{ color: 'red' }}>{errors.message.message}</p>} <br />

      <button className="submit-btn" type="submit">
        SEND YOUR MESSAGE
      </button>
         {result && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>
          {result}
        </p>
      )}
    </form>
    </div>
    </div>
  )
}

export default ContForm