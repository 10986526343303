import React from 'react'
import ContForm from '../../contactform-component/ContForm'
function MedicalBillingPart() {
  return (
    <div>
    <div className='whatwedo-banner'>
        <h1>Medical Billing</h1>
    </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>

    <div style={{margin:'0 10%'}}>
        <h1 className='consulting-right-session-title'  style={{textAlign:'center',margin:'3% 0'}}>Medical Billing</h1>
        <p className='consulting-right-session-content-para' id='medicalcoding' style={{textAlign:'justify'}}>Avanza BPO LLC provides a robust medical billing service designed to ensure complete accuracy, regulatory compliance, and maximum efficiency for your practice. With a focus on capturing all billable services and achieving a high first-pass resolution rate, we streamline the entire billing process to reduce delays and enhance profitability. Our expertise includes managing primary claims, handling complex secondary billing, special invoicing for Workers’ Compensation (W/C) and travelers' insurance, and addressing contract-specific requirements, all to optimize revenue cycle management and cash flow for your practice.</p>
    </div>

    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#medicalcoding"><button style={{backgroundColor:'#243043', color:'white'}} className="consulting-left-session-btn-1">Medical Billing</button></a>
          <a href="#Claim Preparation"><button className="consulting-left-session-btn-1">Claim Preparation</button></a>
          <a href="#Claim Submission"><button className="consulting-left-session-btn-1">Claim Submission</button></a>

        </div>
        <div className='consulting-right-session'>
        <h1 id='Claim Preparation' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Medical Billing Services</h1>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Claim Preparation</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Thorough Claim Review:</h1>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Each claim undergoes a rigorous review to confirm that all required information is accurate and complete, ready for submission.</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	We proactively correct any system-generated errors and align diagnosis codes to the relevant procedures to demonstrate medical necessity, which enhances the likelihood of prompt claim approval.</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Secondary Billing:</h1>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Our team efficiently manages secondary billing by coordinating with secondary insurers, ensuring that any patient liability is accurately processed after primary insurance has responded.</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Specialized billing for W/C, Travelers, MVA and Special Contracts:</h1>
          <p id='Claim Submission' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	We are experienced in handling special cases like Workers’ Compensation, MVAs’ and travelers' insurance, as well as unique contract billing terms. This includes custom invoicing and meeting specific requirements set by these insurers, ensuring all reimbursements are captured accurately.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Claim Submission</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Seamless Claim Submission Process:</h1>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Claims are submitted to insurance companies both electronically and manually, depending on the practice management system in use.</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	We ensure timely submission to avoid reimbursement delays and prevent timely filing denials.</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Clearinghouse and Insurance Rejection Management</h1>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Our team monitors clearinghouse rejections closely, promptly addressing any issues that arise. We investigate the cause of rejections, correct errors, and resubmit claims quickly to prevent revenue delays.</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Insurance rejections are meticulously analyzed, with corrections made and resubmissions completed to maximize the likelihood of successful reimbursement.</p>
        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default MedicalBillingPart