import React from 'react'
import './speciality.css'
import { Link } from 'react-router-dom'
import ContArea from '../../cont-small component/ContArea'

function Specialities() {
  return (
    <div>
      <div className='speciality-banner'>
        <h1>Specialities</h1>
        <p>Driving Your Practice Forward with Unmatched Specialization</p>
      </div>
      <div style={{width:'70%',margin:'3% 15%',textAlign:'center',fontSize:'20px'}}>
        <h1>Innovative Expertise for Every Aspect of Your Practice</h1>
        <p style={{margin:'2% 0 2% 0'}}>All the services provided by Avanza
        BPO LLC are designed to support all the specialties listed below and more. For detailed information, please refer to our <a href="/whatweoffer" style={{textDecoration:'underline'}}>"What We Offer"</a> section or <a href="/contactus" style={{textDecoration:'underline'}}>contact us</a> directly.</p>
        <hr />
      </div>
      <div className='spl-card-sec'>
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Internal Medicine</p>
        </div>
        <div className='spl-card-back'>
          <div >
          <h5 >Internal Medicine</h5>
          <Link to="/contactus#formposition"><p>Elevate your internal medicine practice with our expert consulting services. Our solutions are customized to address the unique challenges of managing complex patient cases, optimizing coding practices, and enhancing operational efficiency, helping you deliver high-quality care while maximizing practice performance."</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Mental Health</p>
        </div>
        <div className='spl-card-back'>
        <div >
          <h5 >Mental Health</h5>
          <Link to="/contactus#formposition"><p>Enhance your Mental Health practice with our customized consulting services that address the specific demands of women's health. We offer solutions to streamline patient management, improve billing processes, and ensure compliance with regulatory standards, allowing you to focus on providing superior care to women at all stages of life.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Orthopedics</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Orthopedics</h5>
          <Link to="/contactus#formposition"><p>Boost the efficiency of your orthopedic practice with our expert consulting services. We provide customized solutions to streamline patient scheduling, improve claims processing, and manage complex billing requirements, helping you deliver top-notch care while optimizing practice operations and revenue cycle management.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Cardiology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Cardiology</h5>
          <Link to="/contactus#formposition"><p>Optimize your cardiology practice with our specialized consulting services designed to meet the complex needs of cardiac care. From enhancing coding accuracy to improving patient flow and billing processes, our solutions are customized to help you manage your practice efficiently while delivering exceptional cardiovascular care.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Dermatology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Dermatology</h5>
          <Link to="/contactus#formposition"><p>Streamline your dermatology practice with our consulting services customized to the unique needs of skin care specialists. We offer solutions to enhance patient scheduling, improve billing practices, and ensure compliance with industry standards, allowing you to focus on providing the highest quality dermatological care.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Gastroenterology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Gastroenterology</h5>
          <Link to="/contactus#formposition"><p>Elevate your gastroenterology practice with our expert consulting services designed to address the complexities of digestive health care. Our solutions help streamline administrative processes, improve billing accuracy, and enhance patient care, ensuring your practice operates efficiently and effectively.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Urology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Urology</h5>
          <Link to="/contactus#formposition"><p>Transform your urology practice with our consulting services customized to meet the demands of specialized care. We provide solutions to improve patient management, optimize billing processes, and enhance operational efficiency, enabling you to focus on delivering exceptional care for urinary and reproductive health.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Endocrinology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Endocrinology</h5>
          <Link to="/contactus#formposition"><p>Enhance your endocrinology practice with our comprehensive consulting services designed to address the unique challenges of managing endocrine disorders. From optimizing coding and billing practices to improving patient scheduling and workflow, our solutions ensure your practice runs smoothly while providing excellent care.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Rheumatology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Rheumatology</h5>
          <Link to="/contactus#formposition"><p>Improve your rheumatology practice with our specialized consulting services focused on managing autoimmune and inflammatory conditions. Our solutions streamline patient management, enhance billing accuracy, and ensure compliance, helping you provide superior care while optimizing your practice's operations.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Oncology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Oncology</h5>
          <Link to="/contactus#formposition"><p>Optimize your oncology practice with our customized consulting services designed to support the complexities of cancer care. We offer solutions to enhance billing and coding accuracy, improve patient flow, and streamline administrative processes, ensuring your practice operates efficiently while delivering compassionate care.</p></Link> 
          </div>
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Pulmonology</p>
        </div>
        <div className='spl-card-back'>
          <div>
              <h5 >Pulmonology</h5>
          <Link to="/contactus#formposition"><p>Elevate your pulmonology practice with our consulting services designed to address the specific needs of respiratory care. Our solutions help optimize patient management, improve billing practices, and enhance operational efficiency, allowing you to focus on delivering high-quality care for patients with respiratory conditions.</p></Link> 
          </div>
      
        </div>
      </div> 
       <div className="spl-card">
        <div className='spl-card-front'>
        <p>Neurology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Neurology</h5>
          <Link to="/contactus#formposition"><p>Transform your neurology practice with our expert consulting services customized to the complexities of neurological care. We provide solutions to streamline patient management, enhance billing processes, and improve operational efficiency, helping you deliver exceptional care for patients with neurological disorders.</p></Link> 
          </div>
        </div>
      </div>
      <div className="spl-card">
        <div className='spl-card-front'>
        <p>Radiology</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Radiology</h5>
          <Link to="/contactus#formposition"><p>Optimize your radiology practice with our consulting services customized to the demands of diagnostic imaging. We offer solutions to improve workflow efficiency, enhance billing processes, and manage complex coding requirements, helping you deliver accurate and timely diagnostic services while streamlining operations.</p></Link> 
          </div>
        </div>
      </div>
      <div className="spl-card">
        <div className='spl-card-front'>
        <p>Chiropractic</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Chiropractic</h5>
          <Link to="/contactus#formposition"><p>Transform your chiropractic practice with our specialized consulting services designed to support holistic and manual care. We offer solutions to streamline patient management, enhance billing accuracy, and optimize practice operations, allowing you to focus on providing effective chiropractic treatments.</p></Link> 
          </div>
        </div>
      </div>
      <div className="spl-card">
        <div className='spl-card-front'>
        <p>Psychiatry</p>
        </div>
        <div className='spl-card-back'>
          <div>
          <h5 >Psychiatry</h5>
          <Link to="/contactus#formposition"><p>Optimize your psychiatry practice with our expert consulting services customized to mental health care. We provide solutions to enhance patient management, improve billing practices, and streamline administrative processes, helping you deliver high-quality mental health services while maintaining efficient practice operations.</p></Link> 
          </div>
        </div>
      </div>
      </div>
      <ContArea />
    </div>
  )
}

export default Specialities