import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function Remittance() {
  return (
    <div>
            <div className='whatwedo-banner'>
        <h1>Remittance Posting</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
   
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Electronic and Manual Payment Posting"><button className="consulting-left-session-btn-1">Electronic and Manual Payment Posting</button></a>
          <a href="#Insurance Payment Posting"><button className="consulting-left-session-btn-2">Insurance Payment Posting with Contracted Rate Verification</button></a>
          <a href="#Patient Payment Posting"><button className="consulting-left-session-btn-3">Patient Payment Posting</button></a>
          <a href="#Denial Posting and Management"><button className="consulting-left-session-btn-4">Denial Posting and Management</button></a>
          <a href="#Credit Balance Management"><button className="consulting-left-session-btn-4">Credit Balance Management</button></a>
          <a href="#Reconciliation"><button className="consulting-left-session-btn-4">Reconciliation</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' id='Electronic and Manual Payment Posting' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Remittance Posting</h1>
        <p className='consulting-right-session-content-para' style={{textAlign:'justify',padding:'2% 5% 2% 5%'}}>At Avanza BPO LLC, we offer remittance posting as a crucial component of revenue cycle management, ensuring that payments are accurately recorded, reconciled, and comply with payer contracts. Our comprehensive, end-to-end remittance posting services are designed to streamline this process for healthcare providers.</p>

        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Electronic and Manual Payment Posting</p>
          <p id="Insurance Payment Posting" className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>We handle both electronic remittance advices (ERA) and manual explanations of benefits (EOB), accurately entering payment data regardless of the format.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Insurance Payment Posting with Contracted Rate Verification</p>
          <p id='Patient Payment Posting' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>Our team posts insurance payments while ensuring each amount aligns with the payer's contracted rates. This ensures correct reimbursement and minimizes the risk of underpayments or overpayments, keeping accounts accurate and compliant.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'style={{color:'#cf7531',fontWeight:'600'}}>- Patient Payment Posting</p>
          <p id='Denial Posting and Management' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>Patient payments received through various channels are accurately recorded to maintain up-to-date account balances, ensuring transparency for both providers and patients.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'style={{color:'#cf7531',fontWeight:'600'}}>- Denial Posting and Management</p>
          <p id='Credit Balance Management'className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>We document denial reasons and initiate follow-up processes to address issues quickly, minimizing revenue loss and reducing AR days.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'  style={{color:'#cf7531',fontWeight:'600'}}>- Credit Balance Management</p>
          <p id='Reconciliation' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>Our team identifies and corrects credit balances on patient accounts, issuing refunds or adjustments as needed to prevent overbilling and ensure regulatory compliance.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Reconciliation</p>
          <p id='Insurance Coordination' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>We reconcile posted payments against deposited amounts, ensuring that all transactions align with payer agreements and financial records are accurate.</p>
        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default Remittance