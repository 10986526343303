import React from 'react'
import ContForm from '../../contactform-component/ContForm'
function ConsultingPackage() {
  return (
    <div>
        <div className='whatwedo-banner'>
        <h1>Consulting Package</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Monthly Analysis"><button className="consulting-left-session-btn-1">Monthly Analysis</button></a>
          <a href="#Training Component"><button className="consulting-left-session-btn-2">Training Component</button></a>
          <a href="#Resource Material"><button className="consulting-left-session-btn-3">Resource Material</button></a>
          <a href="#Consultation Calls"><button className="consulting-left-session-btn-4">Consultation Calls</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' id="Monthly Analysis" >Consulting Package</h1>
        <p className='consulting-right-session-content-para' style={{textAlign:'justify',padding:'2% 5% 2% 5%'}}>Avanza BPO LLC offers a comprehensive Consulting Package for healthcare providers, focused on enhancing operational efficiency, optimizing revenue cycle management, and supporting sustainable growth. Our responsive consultation services include ongoing support with open Q&A sessions, allowing clients to address new questions and maintain best practices in revenue cycle management.</p>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' >- Monthly Analysis</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Detailed Reports: </h1>
          <p className='consulting-right-session-content-para' >Each month, we conduct a comprehensive analysis of your operations, delivering detailed reports that pinpoint areas of improvement within revenue cycle management, billing processes, and overall financial health. These reports provide actionable insights to help optimize efficiency and boost financial performance.</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Explanation Session: </h1>
          <p id="Training Component" className='consulting-right-session-content-para'>To ensure the insights are effectively understood and actionable, a dedicated Avanza team member will review the report findings with your designated representative. This session clarifies the analysis and provides guidance on how to implement recommended improvements.</p>
        </div>
        <hr  />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Training Component</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Business-Specific Training: </h1>
          <p id="Resource Material" className='consulting-right-session-content-para'>Each quarter, we will provide up to 2 hours ($400 Value) of focused training to further support and develop your team. This training can be delivered to individual employees or teams in similar roles, focusing on skills that address your unique business needs. The goal is to empower your staff with enhanced expertise in areas critical to your operations, such as claims management, billing accuracy, and patient account handling.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Resource Material </p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Avanza’s Comprehensive Guide to Denial <span style={{color:'#243043',fontWeight:'600',marginLeft:'25px'}} >Management: </span></h1>
          <p id="Consultation Calls" className='consulting-right-session-content-para'>Included in the package is access to our in-depth guide, offering strategies and best practices for managing denials effectively.</p>
        </div>
        <hr  />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title'>- Consultation Calls </p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Open Q&A Sessions: </h1>
          <p className='consulting-right-session-content-para'>Clients have access to scheduled consultation calls for open Q&A sessions on new topics related to Revenue Cycle Management (RCM).</p>
        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default ConsultingPackage