import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function PersonalizedTrainning() {
  return (
    <div>
    <div className='whatwedo-banner'>
        <h1>Personalized Training for Operations Staff</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Initial Assessment"><button className="consulting-left-session-btn-1">Initial Assessment</button></a>
          <a href="#Flexible Training Options"><button className="consulting-left-session-btn-2">Flexible Training Options</button></a>
          <a href="#Customized Curriculum"><button className="consulting-left-session-btn-3">Customized Curriculum</button></a>
          <a href="#Follow-up Support and Q&A Session"><button className="consulting-left-session-btn-4">Follow-up Support and Q&A Session</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' >Personalized Training for Operations Staff</h1>
        <p className='consulting-right-session-content-para' style={{textAlign:'justify',padding:'2% 5% 2% 5%'}}>Avanza BPO LLC offers a Personalized Training for Operations Staff program, designed to elevate team performance, fill specific skill gaps, and enhance productivity across your operations team. Our training program is crafted to address the unique challenges faced by your operations staff, focusing on enhancing efficiency and meeting the specific needs of your practice.</p>

        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Service Description </p>
          <p id="Initial Assessment" className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Our Personalized Training for Operations Staff is designed to enhance team efficiency and address specific skill gaps.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Initial Assessment</p>
          <p id='Flexible Training Options' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>We begin with a thorough assessment to identify the particular training needs of your team. This assessment allows us to focus on practice-specific requirements, ensuring the training is directly applicable to your organization.</p>
        </div>
        <hr  />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Flexible Training Options</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Customized Format:</h1>
          <p className='consulting-right-session-content-para' >Training sessions can be conducted as one-on-one sessions or in group settings, depending on the size and structure of your team.</p>
          <h1 style={{margin:'3% 0 0 0'}}>•	Delivery Options:</h1>
          <p id="Customized Curriculum" className='consulting-right-session-content-para'>We offer training sessions both in person and via Zoom, allowing you to choose the format that best fits your operational setup and scheduling needs.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p  className='consulting-right-session-content-title' >- Customized Curriculum </p>
          <p id='Follow-up Support and Q&A Session' className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>Each training program is customized to tackle the specific challenges and goals of your organization. By customizing the curriculum, we ensure that every session is relevant, impactful, and aligned with your business objectives.</p>
        </div>
        <hr />
        <div className='consulting-right-session-content' style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' >- Follow-up Support and Q&A Session</p>
          <p className='consulting-right-session-content-para' style={{margin:'0 0 0 3%'}}>To maximize the impact of our training, Avanza BPO LLC includes a 30-minute Q&A session after each training. This session allows team members to ask questions and seek clarification on any training topics, ensuring they have a clear understanding of how to apply new skills in their daily tasks.</p>

        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default PersonalizedTrainning